/**
 * Timetastic
 * Media enquiries page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/media.scss"

// SEO
const title = "Our brand guidelines and logos"
const description =
  "The official Timetastic logos and resources for use on your website or mobile application."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      {/*
    Timetastic SVG logo,
    add in a hidden div and reference with a `<use>` as it’s
    used twice on this page and this approach saves bytes
    move to Layout.js if the full stop gets removed and then we can use this SVG in the header and footer too
    */}
      <div style={{ display: "none" }}>
        <svg>
          <defs>
            <g id="TimetasticLogo">
              <path d="M38.318 50.73c-1.699 2.238-3.398 3.396-5.173 3.395-2.085 0-5.327-2.163-7.41-4.942-.85-1.236-1.236-3.012-1.236-5.25.001-2.008.388-4.865 1.16-8.57.773-3.629.687-4.153 1.537-6.855.85-3.01 1.545-5.018 1.93-5.867 1.39 0 3.012.31 4.865.85 1.775.618 2.702 1.313 2.702 2.008.23.617-.84 1.219-2.85 8.707-1.467 5.404-2.163 9.496-2.164 12.276-.31 5.79 3.86 2.162 7.181-3.164 1.468-2.548 2.703-5.018 3.707-7.412.232-.617.618-.926 1.004-.926.54 0 1.235.85 1.235 1.699 0 .463-.154 1.08-.463 1.853-2.395 5.867-4.403 9.958-6.025 12.197" />
              <path d="M85.643 49.966c-2.085 2.856-4.17 4.323-6.1 4.322-1.003 0-2.856-1.776-4.245-2.471-1.93-.927-2.857-2.317-2.856-4.247 0-3.088.696-6.794 2.086-11.117.31-1.312 1.314-3.782 1.314-3.937l-.155-.077c-.695.385-1.776 1.543-3.243 3.319a53.85 53.85 0 00-3.938 5.712c-1.159 2.007-2.163 4.169-3.09 6.562-.927 2.393-1.468 4.014-1.7 5.018-.077 1.004-.926 1.158-3.628-.31-2.239-1.158-3.32-2.316-3.32-3.551.002-3.166.62-7.258 1.856-12.275.387-1.467 1.082-4.247 2.086-8.262l-.232-.154c-2.548 2.856-5.019 6.562-7.413 11.116-.309.695-1.699 3.32-2.008 4.014-.232.387-.464.927-.85 1.776-1.003 2.239-1.39 3.629-1.931 6.408 0 1.158-.31 2.316-1.004 2.316-.54 0-1.544-.31-3.01-1.005-1.467-.695-2.471-1.313-2.934-1.853-.386-.386-.54-1.158-.54-2.316 0-.231.154-1.93.54-5.173.388-3.397 1.16-7.566 2.32-12.43 1.158-4.863 2.24-8.8 3.322-11.888 1.389 0 2.933.31 4.632.927 1.698.618 2.547 1.236 2.547 1.854 0 .231-.927 3.242-2.858 9.109-1.082 3.474-1.854 6.562-2.395 9.342l.232.077c2.24-4.71 5.02-9.65 8.186-14.977 2.857-4.708 5.406-7.024 7.721-7.024 1.159 0 2.471.387 3.784 1.159 1.312.772 2.006 1.468 2.238 2.008-.232.618-1.236 3.088-2.704 7.41-2.548 7.18-4.016 11.581-4.325 13.357l.231.077c2.858-5.095 5.715-9.65 8.65-13.587 2.703-3.705 4.787-5.558 6.177-5.557 1.467 0 2.934.386 4.4 1.082 1.39.772 2.161 1.467 2.393 2.085-.232.694-1.853 4.477-3.63 9.11-1.776 5.017-2.704 8.722-2.704 11.27 0 1.004.231 1.545.772 1.545 2.239 0 6.254-4.632 9.266-11.502.618-1.467 1.159-2.239 1.622-2.239.85 0 1.235.54 1.235 1.545 0 .54-.463 1.853-1.313 3.86-1.545 3.474-3.399 6.716-5.484 9.572" />
              <path d="M116.914 34.994c.618 0 1.235.85 1.235 1.854 0 .386-.077.771-.232 1.157-4.943 12.352-10.812 16.753-16.139 16.751-1.698 0-3.551-.618-5.558-1.854-4.246-2.703-6.407-6.564-6.406-11.427.001-5.714 1.237-10.963 3.632-15.673 2.394-4.709 5.328-7.101 8.88-7.1 2.084 0 3.86 1.081 4.477 1.699a29.532 29.532 0 011.775 1.93c2.239 1.39 3.397 3.166 3.397 5.25 0 3.243-1.623 6.486-4.866 9.805-3.243 3.319-6.1 4.94-8.648 4.94a9.096 9.096 0 01-1.699-.155v.772c0 4.863 1.697 7.258 5.094 7.258 3.088 0 8.03-3.241 11.042-8.259a48.544 48.544 0 002.857-5.635c.387-.85.773-1.313 1.16-1.313m-11.888-10.656c-1.468 0-3.09 1.543-4.788 4.709-1.699 3.164-2.78 6.716-3.245 10.653 1.776-.385 4.016-2.624 4.942-3.86.464-.617 1.081-1.543 1.931-2.778 1.7-2.702 2.549-4.94 2.549-6.794 0-1.312-.463-1.93-1.39-1.93" />
              <path d="M136.833 36.697c0 .463-.078.926-.31 1.312-4.557 11.503-9.19 16.752-12.356 16.752-1.775 0-3.859-1.082-6.098-3.244-2.316-2.085-3.473-4.093-3.473-5.869 0-5.095 1.624-13.356 4.868-24.781h-2.856c-1.235-.001-2.008-1.854-2.007-3.707 0-.309.077-.695.155-1.08l6.022.077c1.777-5.635 3.09-9.187 3.862-10.807.309-.078.772-.077 1.313-.077 3.396 0 6.176 1.159 6.176 2.317 0 .231-.464 1.39-1.314 3.628-.54 1.39-1.158 3.088-1.854 5.018 1.622 0 3.397-.076 5.25-.153 1.159-.077 2.007 1.853 2.007 3.706 0 .309-.077.695-.154 1.081l-8.57-.002c-3.785 12.044-5.64 20.844-5.641 26.326 0 1.698 0 2.857 1.158 2.857 1.852 0 5.405-2.547 8.185-7.256 1.235-2.084 2.317-4.246 3.166-6.485.85-2.547 2.316-.926 2.47.387" />
              <path d="M149.956 42.644c-3.321 7.951-6.41 11.888-9.113 11.887-1.466 0-3.01-.927-4.631-2.78-1.699-1.853-2.47-4.71-2.469-8.647 0-6.177 1.933-14.283 5.949-19.995 1.93-2.856 4.092-4.245 6.33-4.245 2.394 0 4.556.927 5.791 1.777.618.463 1.776 1.39 3.55 2.703.387-.078.85-.155 1.314-.155 2.702 0 4.632.773 5.712 2.395 0 .077-1.699 4.014-2.704 7.025-1.93 5.79-2.935 10.499-2.936 14.205 0 1.853.385 2.78 1.158 2.78 1.312 0 2.78-1.467 4.478-4.477 1.7-3.01 3.09-5.945 4.171-8.955.31-.772.695-1.158 1.158-1.158.618 0 1.236.85 1.235 1.853 0 .309-.386 1.39-1.08 3.243-3.786 9.65-7.03 14.435-9.886 14.435-.85 0-2.084-.464-3.706-1.39-2.007-1.16-3.241-2.163-3.782-3.167-.463-.85-.694-1.853-.694-3.088 0-.85.155-2.24.386-4.169zm-8.185 6.097c1.159 0 2.857-1.852 4.942-5.48 2.086-3.628 3.94-7.874 5.562-12.738l.849-3.088c-.54-2.085-1.543-3.165-2.856-3.166-2.007 0-4.092 2.547-6.255 7.565-2.163 5.018-3.244 9.727-3.245 14.128 0 1.852.309 2.78 1.003 2.78" />
              <path d="M191.184 36.167c.926.386 1.389 1.312 1.389 2.933 0 1.236-.387 2.162-.772 1.93-1.699-.849-3.63-1.313-5.714-1.313-1.158 0-2.625.154-4.555.54.695 2.393 1.003 4.478 1.003 6.253 0 1.853-.773 3.783-2.24 5.713-1.545 1.93-3.397 2.933-5.56 2.933-1.776 0-3.474-.85-5.249-2.472-1.775-1.621-2.624-3.32-2.624-5.096 0-2.702 2.471-5.25 7.336-7.642-.232-.618-1.39-3.397-3.395-8.339-1.082 3.32-1.7 5.25-1.932 5.79-.309.772-.695 1.158-1.235 1.158-.772 0-1.158-.54-1.158-1.621 0-.386.154-.85.386-1.544.617-1.313 1.545-4.014 2.858-8.183v-.31c.001-4.631 1.469-7.72 3.244-8.568a3.585 3.585 0 011.468-.309c.85 0 1.621.232 2.47.618 1.313.618 2.47 1.545 3.551 2.78-1.853 1.621-2.78 3.706-2.78 6.253 0 1.313.385 2.549 1.311 4.942.462 1.235.85 2.239 1.08 3.088.154.463.463 1.467.617 1.854 3.629-1.081 6.563-1.62 8.802-1.62.695 0 1.313.077 1.699.232m-18.532 14.897c1.544 0 3.32-2.316 3.32-4.787 0-.849-.23-2.084-.616-3.706-2.935 1.776-4.402 3.937-4.403 6.408 0 1.39.541 2.084 1.699 2.085" />
              <path d="M209.866 36.71c0 .464-.077.927-.309 1.313-4.557 11.502-9.19 16.751-12.355 16.751-1.776 0-3.86-1.082-6.1-3.244-2.315-2.085-3.473-4.092-3.472-5.868 0-5.095 1.624-13.356 4.868-24.782h-2.856c-1.236 0-2.007-1.854-2.007-3.707 0-.308.077-.695.155-1.08l6.022.078c1.777-5.636 3.09-9.187 3.862-10.808.309-.077.772-.077 1.312-.077 3.397 0 6.176 1.16 6.176 2.317 0 .232-.463 1.39-1.313 3.629-.54 1.39-1.158 3.088-1.854 5.018 1.622 0 3.397-.077 5.25-.154 1.159-.077 2.008 1.854 2.007 3.706 0 .31-.078.695-.155 1.082l-8.57-.002c-3.784 12.043-5.64 20.844-5.64 26.325 0 1.699 0 2.857 1.157 2.857 1.853 0 5.406-2.546 8.185-7.255 1.236-2.085 2.318-4.246 3.166-6.485.85-2.547 2.317-.926 2.471.386" />
              <path d="M220.441 50.764c-1.7 2.238-3.399 3.396-5.174 3.396-2.084 0-5.327-2.163-7.41-4.943-.849-1.235-1.235-3.011-1.235-5.25 0-2.007.387-4.864 1.16-8.57.773-3.628 1.545-6.87 2.396-9.572.85-3.011 1.545-5.018 1.93-5.867 1.39 0 3.012.31 4.864.85 1.775.618 2.702 1.313 2.702 2.007.23.618-1.7 3.938-3.708 11.426-1.468 5.404-2.164 9.496-2.165 12.275-.309 5.79 3.861 2.163 7.182-3.164 1.467-2.547 2.703-5.017 3.707-7.41.232-.618.618-.927 1.004-.927.54 0 1.235.85 1.234 1.699 0 .463-.153 1.08-.463 1.853-2.394 5.867-4.402 9.958-6.024 12.197m2.94-39.374c-1.389 1.466-2.856 2.238-4.4 2.238-2.085 0-3.397-1.39-3.397-3.784 0-1.621.619-3.242 1.778-4.786 1.158-1.544 2.548-2.316 4.169-2.315 2.47 0 3.937 1.853 3.936 4.092 0 1.544-.695 3.088-2.085 4.555" />
              <path d="M245.3 50.537c-2.703 2.856-5.405 4.245-8.108 4.245-2.548 0-5.327-1.39-8.337-4.248-3.01-2.857-4.478-5.945-4.477-9.265.001-6.1 1.393-11.272 4.096-15.672 2.702-4.4 5.714-6.562 9.188-6.56 1.93 0 3.86 1.08 4.478 1.622l1.775 1.775c2.547 1.313 3.86 2.935 3.86 4.865-.001 1.158-.62 2.625-1.932 4.555-1.313 1.93-2.625 3.473-4.016 4.477-.771.617-1.544.926-2.238.926-.386 0-.618-.077-.772-.31-.85-.385 3.552-7.179 3.398-10.73 0-1.158-.385-1.699-1.234-1.699-1.931 0-4.016 1.93-6.178 5.712-2.24 3.783-3.322 8.415-3.323 13.896 0 4.092 1.621 6.1 4.787 6.1 3.396.001 8.029-3.009 11.118-7.872 1.39-2.239 2.471-4.323 3.244-6.253.232-.695.618-1.08 1.004-1.08.695 0 1.312.926 1.312 1.775 0 .463-.386 1.544-1.082 3.242-1.7 4.246-3.862 7.72-6.564 10.5M4.17.848C16.6.848 27.176.54 35.822 0c1.004.85 1.467 2.393 1.467 4.71 0 1.235-.231 1.852-.617 1.852-5.559-.076-10.346-.154-14.438-.154-4.014 15.75-6.948 30.264-8.879 43.466-.231 1.39-.231 1.621-.617 2.162-.232.077-.54.232-1.158.232-2.393 0-4.555-.695-6.64-2.008C6.1 43.851 9.265 29.182 14.283 6.33H2.239C1.313 5.79 0 3.01 0 1.698c0-.54.233-.849.619-.849z" />
              <path d="M39.404 18.256c-1.39 1.466-2.858 2.238-4.401 2.238-2.085 0-3.397-1.39-3.396-3.784 0-1.622.618-3.242 1.777-4.786 1.158-1.544 2.548-2.316 4.17-2.316 2.47.001 3.936 1.854 3.936 4.093 0 1.544-.696 3.088-2.086 4.555" />
              <path
                fill="#E5007E"
                d="M262.575 54.268c-1.39 1.467-2.857 2.239-4.4 2.238-2.085 0-3.398-1.39-3.397-3.783 0-1.622.618-3.243 1.777-4.787 1.158-1.544 2.548-2.315 4.169-2.315 2.47 0 3.937 1.854 3.936 4.092 0 1.545-.695 3.089-2.085 4.555"
              />
            </g>
          </defs>
        </svg>
      </div>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-media-main">
          {/* Hero */}
          <section className="c-section u-text-centre u-text-left-mobile c-media-hero">
            <div className="u-inner">
              <div className="is-unanimated">
                <h1 className="h h2 c-media-hero__title">
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">Timetastic</span> media
                  resources
                </h1>
                <p>
                  These are official Timetastic graphical resources that you can
                  include on your webpage, in your mobile application or in
                  printed material.
                </p>
              </div>
              <div className="is-unanimated">
                <h2 className="h h6">Download our media assets</h2>
                <div className="c-button-list">
                  <a
                    href="/resources/media_assets.zip"
                    className="c-button c-button--primary c-button--xl"
                    download
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      trackCta("MediaAssetsZip")
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      focussable="false"
                      height="14"
                      viewBox="0 0 10 14"
                      width="10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use xlinkHref="#svg-arrow-down" />
                    </svg>
                    Download our asset pack (ZIP)
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section u-text-centre u-text-left-mobile c-media-hero">
            <div className="u-inner">
              <div className="is-unanimated">
                <h2 className="h h2">Logos</h2>
                <div className="c-media-logo-block u-bgcolor-white u-color-navy">
                  <svg
                    aria-label="Timetastic"
                    fill="currentColor"
                    height="213.569"
                    viewBox="0 0 1188 240"
                    width="1000.284"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <use xlinkHref="#svg-logo" />
                  </svg>
                </div>
                <div className="u-text-center">
                  <h3 className="h h4">Light backgrounds</h3>
                  <h4>Available formats</h4>
                  <ul className="u-inline-list u-download-list">
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-Logo.svg"
                        download
                        onClick={() => {
                          trackCta("MediaLogoBlueSVG")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        SVG
                      </a>
                    </li>
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-Logo.png"
                        download
                        onClick={() => {
                          trackCta("MediaLogoBluePNG")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        PNG
                      </a>
                    </li>
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-Logo.eps"
                        download
                        onClick={() => {
                          trackCta("MediaLogoBlueEPS")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        EPS
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="c-media-logo-block u-bgcolor-navy">
                  <svg
                    aria-label="Timetastic"
                    fill="currentColor"
                    height="213.569"
                    viewBox="0 0 1188 240"
                    width="1000.284"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <use xlinkHref="#svg-logo" />
                  </svg>
                </div>
                <div className="u-text-center">
                  <h3 className="h h4">Dark backgrounds</h3>
                  <ul className="u-inline-list u-download-list">
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-Logo-White.svg"
                        download
                        onClick={() => {
                          trackCta("MediaLogoLogoSVG")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        SVG
                      </a>
                    </li>
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-Logo-White.png"
                        download
                        onClick={() => {
                          trackCta("MediaLogoLogoPNG")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        PNG
                      </a>
                    </li>
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-Logo-White.eps"
                        download
                        onClick={() => {
                          trackCta("MediaLogoLogoEPS")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        EPS
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="is-unanimated">
                <h2 className="h h2">App Icon</h2>
                <div className="c-media-logo-block u-bgcolor-white u-color-navy">
                  <svg
                    aria-label="App icon"
                    // fill="currentColor"
                    height="400"
                    viewBox="0 0 1024 1024"
                    width="400"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <use xlinkHref="#app-icon" />
                  </svg>
                </div>
                <div className="u-text-center">
                  {/* <h3 className="h h4">Default navy theme</h3> */}
                  <h4>Available formats</h4>
                  <ul className="u-inline-list u-download-list">
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-App-Icon.svg"
                        download
                        onClick={() => {
                          trackCta("MediaLogoBlueSVG")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        SVG
                      </a>
                    </li>
                    <li>
                      <a
                        className="u-download-list__a"
                        href="/resources/Timetastic-App-Icon.png"
                        download
                        onClick={() => {
                          trackCta("MediaLogoBluePNG")
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focussable="false"
                          height="14"
                          viewBox="0 0 10 14"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref="#svg-arrow-down" />
                        </svg>
                        PNG
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="is-unanimated">
                <h2 className="h h2">Colours</h2>
                <table className="c-media-table">
                  <thead>
                    <tr>
                      <th className="u-bgcolor-navy h h4" scope="col">
                        Black
                      </th>
                      <th className="u-bgcolor-cherry h h4" scope="col">
                        Pink
                      </th>
                      <th className="u-bgcolor-blue h h4" scope="col">
                        Blue
                      </th>
                      <th className="u-bgcolor-green h h4" scope="col">
                        Green
                      </th>
                      <th className="u-bgcolor-yellow h h4" scope="col">
                        Yellow
                      </th>
                      <th className="u-bgcolor-light-grey h h4" scope="col">
                        Grey
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="u-bgcolor-navy">
                        #2B2A29 <br /> <br />
                        R 43 <br />
                        G 42 <br />
                        B 41 <br />
                      </td>
                      <td className="u-bgcolor-cherry">
                        #E5007E <br /> <br />
                        R 229 <br />
                        G 0 <br />
                        B 126 <br />
                      </td>
                      <td className="u-bgcolor-blue">
                        #007AC9 <br /> <br />
                        R 0 <br />
                        G 122 <br />
                        B 201 <br />
                      </td>
                      <td className="u-bgcolor-green">
                        #7DE0E6 <br /> <br />
                        R 125 <br />
                        G 224 <br />
                        B 230 <br />
                      </td>
                      <td className="u-bgcolor-yellow">
                        #FECC16 <br /> <br />
                        R 254 <br />
                        G 204 <br />
                        B 22 <br />
                      </td>
                      <td className="u-bgcolor-light-grey">
                        #F5F7F7 <br /> <br />
                        R 245 <br />
                        G 247 <br />
                        B 248 <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
